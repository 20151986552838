@import "../mixins/mixins";

.content-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    width: 100%;
    gap: 14px;
    overflow: hidden;

    .header-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;

        @include from($tablet) {
            gap: 18px;
            align-items: flex-end;
        }

        &.center-align {
            @include from($tablet) {
                align-items: center;
            }
        }

        h1 {
            text-transform: capitalize;
            margin-bottom: 0;
        }
    }

    .header-wrapper--with-total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        row-gap: 8px;

        h1 {
            margin: 0;
        }

        .header-title-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            gap: 12px;

            @include from($tablet) {
                gap: 18px;
                align-items: flex-end;
            }

            h1 {
                text-transform: capitalize;
                margin-bottom: 0;
            }
        }
    }

    .header-with-tabs {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .search {
        &-wrapper {
            width: 100%;
        }

        &-form-wrapper {
            align-items: flex-end;
            gap: 16px;
            width: 100%;
        }
    }
}

.button-box {
    display: flex;
    align-items: center;
    gap: 8px;

    .add-label {
        margin-bottom: 0;
        display: none;

        @include from($tablet) {
            display: block;
        }
    }

    .button-icon {
        height: 1.5rem;
        object-fit: contain;
    }
}
