@import "../mixins/mixins.scss";

.resume__ {
    &content-wrapper {
        align-items: center;
        justify-content: center;
        display: flex;
        height: 100vh;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        overflow-y: hidden;

        .resume__content-box {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            overflow-y: hidden;

            @include from($tablet) {
                border-radius: 8px;
                max-width: 375px;
                box-shadow: 2px 2px 12px #80808075;
                max-height: 95vh;
            }

            .resume__top-box {
                background-color: var(--primary-color);
                height: 64px;
                border-bottom: 10px solid #00b9ec;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                padding: 0px 6px 0.5rem 6px;
                color: var(--neutral-color-01);
                width: 100%;
                margin-bottom: 16px;

                @include from ($tablet) {
                    border-radius: 8px 8px 0 0;
                }

                h2 {
                    margin: 0;
                }

                &.paid {
                    background-color: var(--success-color);
                }

                &.cancelled {
                    background-color: var(--danger-color);
                }

                .resume__technician-img {
                    height: 36px;
                    width: 36px;
                    display: flex;
                    justify-content: center;
                    background-color: var(--neutral-color-01);
                    margin-right: 20px;
                    border-radius: 100%;
                    overflow: hidden;

                    img {
                        object-fit: contain;
                        height: 100%;
                        width: 100%;
                    }
                }
            }

            .resume__header-wrapper {
                height: 56px;
                z-index: 100;
                width: 100%;
                padding: 0px 16px;
                margin: 0 auto;
                background-color: var(--neutral-color-01);
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;

                @include from($tablet) {
                    height: 72px;
                }

                .header__upper-box {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 8px;

                    .header__order-number-box {
                        max-width: 80%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 12px;

                        img {
                            height: 1rem;
                            object-fit: contain;
                        }
                    }

                    img {
                        height: 1rem;
                        object-fit: contain;
                        cursor: pointer;

                        @include from($tablet) {
                            height: 1.2rem;
                        }
                    }
                }
            }

            .body-wrapper {
                flex: 1;
                width: 100%;
                padding: 0px 16px;
                margin: 0 auto;
                overflow-y: auto;

                h1 {
                    text-transform: capitalize;
                }

                .footer-wrapper {
                    height: 100px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    column-gap: 36px;
                    row-gap: 12px;
                    margin-bottom: 64px;
                    //margin-top: 18px;
                    background-color: var(--neutral-color-01);
                    z-index: 100;

                    @include from($tablet) {
                        height: 120px;
                        margin-bottom: 18px;
                    }

                    h2 {
                        margin: 0;
                    }
                }

                .client-info-label {
                    width: 100%;
                    height: 36px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: var(--secondary-color);
                    cursor: pointer;
                    border-bottom: 1px solid var(--secondary-color);

                    h5 {
                        margin: 0;
                    }

                    img {
                        height: 12px;
                        object-fit: contain;
                        filter: invert(0.8);

                        &.isOpen {
                            transform: rotate(180deg);
                        }
                    }
                }

                .extra-info-box {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    padding: 14px 24px 14px 12px;
                    border-bottom: 1px solid var(--neutral-color-02);
                    border-top: 1px solid var(--neutral-color-02);

                    .paragraph-2,
                    .paragraph-3 {
                        font-weight: 600;
                    }
                }

                .warning-message-box {
                    margin-top: 24px;
                    font-weight: 500;
                    text-align: center;
                }
            }

            .quotation-resume-wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;

                .items-resume-title {
                    color: var(--primary-color);
                    padding-bottom: 6px;
                    font-weight: 500;
                    border-bottom: 1px solid var(--neutral-color-02);
                    margin: 0;
                }

                .products-box {
                    display: flex;
                    flex-direction: column;
                    gap: 14px;
                    padding: 14px;
                    width: 100%;
                }

                .ammount-box {
                    border-bottom: 1px solid var(--neutral-color-02);
                    border-top: 1px solid var(--neutral-color-02);
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    width: 100%;
                    padding: 14px 24px 14px 0;

                    .ammount-item {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 24px;
                    }
                }

                .total-box {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 24px;
                    padding: 14px 24px 14px 0;

                    .paragraph-2,
                    .paragraph-3 {
                        font-weight: 600;
                    }
                }
            }

            .data-field {
                &-wrapper {
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;
                    gap: 24px;
                    margin-top: 12px;
                    margin-bottom: 24px;

                    &.no-margin-bottom {
                        margin-bottom: 0;
                    }
                }

                &-box {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    width: auto;

                    a {
                        cursor: pointer;
                        text-decoration: underline;
                    }

                    h4 {
                        margin-bottom: 0;
                    }

                    .paragraph-2 {
                        width: 98%;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        margin-top: 8px;
                    }

                    &--block {
                        width: 100%;

                        &--resizeable {
                            @include from($xl-desktop) {
                                width: calc(50% - 24px);
                            }
                        }
                    }

                    &--half-size {
                        width: 100%;

                        @include from($tablet) {
                            width: calc(50% - 24px);
                        }
                    }

                    &.disabled {
                        color: var(--neutral-color-03);
                    }
                }

                &-element {
                    padding: 5px 10px;
                    border-radius: 8px;
                    background-color: var(--bg-primary-color);
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }


        &.send-view {
            height: 100%;

            .resume__content-box {
                height: 95%;
            }
        }
    }
}
