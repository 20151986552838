.nav-bar-wrapper {
    background-color: var(--primary-color);
    height: var(--nav-bar-height);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    color: var(--neutral-color-01);

    .nav-bar__option-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        
        img {
            filter: brightness(0) invert(0.8);
            height: 20px;
            object-fit: contain;
        }
        
        h5 {
            margin: 0;
            font-weight: 300;
        }

        &.active {
            img {
                filter: brightness(0) invert(1);
            }

            h5 {
                font-weight: 500;
            }
        }
    }
}
