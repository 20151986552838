.tab-menu-wrapper {

    width: 100%;
    min-height: 56px;
    border-bottom: 1px solid var(--neutral-color-03);
    display: flex;
    align-items: flex-end;
    gap: 48px;
    overflow-x: auto;

    .tab-option {
        color: var(--neutral-color-03);
        cursor: pointer;

        &.selected {
            font-weight: 600;
            color: var(--black-color);
            border-bottom: 2px solid var(--black-color);
        }
    }
}