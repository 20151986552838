.table {
    &-external-wrapper {
        margin-top: 16px;
        margin-bottom: 12px;
        width: 100%;
        overflow-y: auto;
        min-height: 250px;
        padding-right: 6px;

        &--no-overflow-y {
            min-height: initial;
            overflow-y: initial;
        }
    }
    
    &-header {
        width: 100%;
        display: flex;
        align-items: center;

        h2 {
            margin-right: 24px;
            margin-bottom: 0;
        }
    }

    &-inner-box {
        min-width: 900px;
        padding-right: 10px;
        min-height: 200px;

        &--large {
            min-width: 900px;
        }
    }

    &-box {
        width: 100%;
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
    }

    &-options {
        display: flex;
        gap: 64px;
        font-style: italic;
        color: var(--neutral-color-03);

        h3 {
            cursor: pointer;
            margin-bottom: 0;
            margin-top: 12px;

            &.is-selected {
                color: var(--black-color);
                text-decoration: underline;
                font-weight: 600;
            }
        }
    }
}
