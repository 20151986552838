.modal {
  &-wrapper {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin-bottom: 36px;

      &--payment {
          align-items: center;
          margin-bottom: 0;
      }
  }

  &-buttons-wrapper {
      align-items: flex-end;
      display: flex;
      gap: 16px;
      justify-content: center;
      width: 100%;
      flex-wrap: wrap-reverse;

      @include from ($tablet) {
          gap: 64px;
      }

      &--absolute {
          bottom: 24px;
          left: 0;
          position: absolute;
      }
  }

  &-box {
    @include until($tablet) {
      width: 100% !important;
      min-height: 100% !important;
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
}

.wpwl-container {
  position: relative;
  padding: 10px;
}