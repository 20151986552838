.form, .create__form {
    &-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 32px;
    }

    &-box {
        width: 100%;
    }
}
